import getMatrix from '~/composables/api/searchDiscover/getMatrix'
import getMatrixNosItems from '~/composables/api/cartConditions/getMatrixNosItems'
import getMatrixCartItems from '~/composables/api/cartConditions/getMatrixCartItems'
import type {
    EnrichedMatrixDataSet,
    MatrixData,
    MatrixErrorResponse,
    ResponseBranch,
} from '~/composables/types/api/searchDiscover/getMatrix'
import type { NavigationFailure, RouteLocationRaw } from 'vue-router'
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore'
import type { ApiErrorResponse } from '~/composables/types/api/apiResponse'

export default function useMatrixDataLoader(
    cartUuid: Ref<string | null>,
    errorHandler: (message: ApiErrorResponse) => void
) {
    const matrixFailedResponse: MatrixErrorResponse = {
        matrixError: undefined,
    }
    let matrixData: MatrixData
    const linkToOrder: Ref<undefined | Promise<NavigationFailure> | RouteLocationRaw> = ref()
    const { activeCartState, activeCartName } = useCartsStore()
    const { $hasRole } = useNuxtApp()

    const mapMissingBranchesWithQuantities = (): ResponseBranch[] => {
        const existingBranches = $hasRole(RoleTypes.SUPPLIER) ? [] : matrixData.branches

        const allBranchIds = new Map<string, { branchId: string | null; partnerId: string | null }>()
        matrixData.items.orderItems.forEach((item) =>
            allBranchIds.set(item.branchId, {
                branchId: item.branchId || null,
                partnerId: item.partnerId || null,
            })
        )
        matrixData.items.moduleItems.forEach((item) =>
            allBranchIds.set(item.branchId, {
                branchId: item.branchId || null,
                partnerId: item.partnerId || null,
            })
        )

        const missingBranches = Array.from(allBranchIds.values())
            .filter(
                (branchIdObj) =>
                    !existingBranches.some((branch) => {
                        const branchMatches = branchIdObj.branchId ? branch.label === branchIdObj.branchId : false
                        const partnerMatches = branchIdObj.partnerId
                            ? branch.partnerId === branchIdObj.partnerId
                            : false
                        return branchMatches || partnerMatches
                    })
            )
            .map((branchIdObj) => ({
                label: branchIdObj.branchId || '',
                partnerId: branchIdObj.partnerId || '',
                id: null,
                name: '',
            }))

        return [...missingBranches, ...existingBranches]
    }

    const loadMatrixCartItems = async () => {
        const items = await getMatrixCartItems(matrixData, cartUuid.value)

        if (items.state === 'success') {
            await nextTick(() => {
                matrixData.items.loaded = true
                matrixData.items.cartClosed = items.cartClosed ?? true
                matrixData.items.userFlowState = items.userFlowState ?? ''
                matrixData.items.branchCluster = items.branchCluster ?? null
                matrixData.items.orderItems = items.orderItems ?? []
                matrixData.items.moduleItems = items.moduleItems ?? []
                matrixData.items.modulName = items.modulName ?? null
                matrixData.items.allocationId = items.allocationId ?? null
                matrixData.branches = mapMissingBranchesWithQuantities()
                useMatrixPricesStore().setCartOrderItems(items.orderItems)
            })

            return
        }

        errorHandler(items)
    }

    const loadMatrixData = async (abstractIdOrSku: number | string): Promise<boolean> => {
        const matrixResponseData = await getMatrix(abstractIdOrSku)

        if (matrixResponseData.state === 'failed') {
            matrixFailedResponse.matrixError = matrixResponseData

            if (
                cartUuid.value &&
                !$hasRole(RoleTypes.SUPPLIER) &&
                matrixResponseData.status === ResponseStatusTypes.NOT_FOUND
            ) {
                if (activeCartState === 'CLOSED') {
                    linkToOrder.value = activeCartName ?? ''

                    return false
                }
            }

            return false
        }

        const { ...matrixDataWithoutState } = matrixResponseData
        matrixData = matrixDataWithoutState

        await nextTick(() => {
            matrixData.items.loaded = false
        })

        if (
            $hasRole(RoleTypes.MEMBER) ||
            $hasRole(RoleTypes.EMPLOYEE) ||
            ($hasRole(RoleTypes.SUPPLIER) && cartUuid.value)
        ) {
            await loadMatrixCartItems()
        }

        if (matrixData.nosTabVisible) {
            const nosItems = await getMatrixNosItems(matrixData)

            if (nosItems.state === 'success') {
                await nextTick(() => {
                    matrixData.items.nosLoaded = true
                    matrixData.items.nosItems = nosItems.nosItems
                })
            }
        }

        return true
    }

    const getResponseData = (): EnrichedMatrixDataSet => ({
        ...matrixData,
        ...matrixFailedResponse,
    })

    return {
        loadMatrixData,
        loadMatrixCartItems,
        getResponseData,
        linkToOrder,
    }
}
