import type { PromiseResponseData } from '~/composables/types/api/apiResponse'
import handleError, { handleSuccess } from '~/composables/api/handleResponse'
import type { MatrixData } from '~/composables/types/api/searchDiscover/getMatrix'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function clearMatrix(matrixData: MatrixData, cartUuid?: string): PromiseResponseData {
    const cartsStore = useCartsStore()
    const url = '/models:delete'
    const { fetchCcCartApi } = fetchOrderDetermination()

    const params = [
        {
            cartId: cartUuid ?? cartsStore.activeCart,
            supplierId: matrixData.supplierNumber,
            modelCode: matrixData.modelNumberFrontend,
            brandCode: matrixData.brandCode === '' ? '-' : matrixData.brandCode,
        },
    ]

    try {
        await fetchCcCartApi(url, {
            method: 'POST',
            body: params,
        })

        return handleSuccess({})
    } catch (error) {
        return handleError(error)
    }
}
