import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import type {
    AdditionalOrderField,
    AdditionalOrderFieldGroup,
    AdditionalOrderFieldResponse,
    AdditionalOrderFieldSelectOption,
} from '~/composables/types/api/searchDiscover'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function getAdditionalOrderFields(): Promise<AdditionalOrderFieldResponse | ApiResponse> {
    const { fetchCcOrderApi } = fetchOrderDetermination()

    try {
        const response = (await fetchCcOrderApi('/erp-daten')) as any

        if (response?.error) {
            return handleError<AdditionalOrderFieldResponse>(response)
        }

        const groups: AdditionalOrderFieldGroup[] = []

        if (response?.fields) {
            const mappedFields: AdditionalOrderField[] = []

            for (const field of response.fields) {
                const mappedSelectOptions: AdditionalOrderFieldSelectOption[] = []
                if (field.selectOptions) {
                    for (const option of field.selectOptions) {
                        mappedSelectOptions.push({
                            name: option.name,
                            value: option.value,
                        })
                    }
                }

                if (field.type === 'SELECT' && mappedSelectOptions.length === 0) {
                    continue
                }

                switch (field.type) {
                    case 'FLT':
                        field.type = 'float'
                        break
                    case 'INT':
                        field.type = 'int'
                        break
                    case 'BOL':
                        field.type = 'boolean'
                        break
                    case 'STR':
                        field.type = 'string'
                        break
                    case 'COD':
                        field.type = 'text'
                        break
                    case 'SELECT':
                        field.type = 'select'
                        break
                    default:
                        console.error(`Unexpected field type ${field.type}`)
                        break
                }

                mappedFields.push({
                    name: field.name,
                    required: field.required,
                    size: field.size ?? 0,
                    exactness: field.precision ?? 0,
                    type: field.type,
                    inherited: field.inherited,
                    selectOptions: mappedSelectOptions,
                    fieldId: field.fieldId,
                    fieldsetId: field.fieldSetId,
                })
            }

            if (mappedFields.length > 0) {
                groups.push({
                    fieldsetId: response.fieldSetId,
                    name: response.name,
                    fields: mappedFields,
                })
            }
        }

        return {
            groups: groups,
        }
    } catch (error) {
        return handleError(error)
    }
}
