import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'
import type { AdditionalOrderFieldValues } from '~/composables/types/api/searchDiscover'

export interface FieldValuesParams {
    brandCode: string
    modelCode: string
}

export interface AdditionalOrderFieldsValues extends ApiResponse {
    brandCode: string
    modelCode: string
    fields: AdditionalOrderFieldValues[]
}

export default async function getAdditionalOrderFieldsValues(
    params: FieldValuesParams
): Promise<AdditionalOrderFieldsValues> {
    const brandCode = params.brandCode
    const cartId = useCartsStore().activeCart || ''
    const url = `/additional-order-fields/${cartId}/${params.modelCode}/${brandCode}`
    const { fetchCcCartApi } = fetchOrderDetermination()

    try {
        const response = (await fetchCcCartApi(url)) as AdditionalOrderFieldsValues

        return response as AdditionalOrderFieldsValues
    } catch (error) {
        return handleError(error) as any
    }
}
