import handleError, { handleSuccess } from '~/composables/api/handleResponse'
import type { UserFlowStateUpdateParams } from '~/composables/types/api/cartConditions/userflow'
import type { ApiGenericErrorResponse, PromiseResponseData } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function updateUserFlowState(
    params: UserFlowStateUpdateParams,
    state: 'EDITING' | 'DONE'
): PromiseResponseData<object> {
    const { fetchCcCartApi } = fetchOrderDetermination()

    try {
        const response = <any>await fetchCcCartApi(`/items/user-flow-state?state=${state}`, {
            method: 'PATCH',
            body: {
                ...params,
            },
        })

        if (response?.error) {
            return handleError(response)
        }

        return handleSuccess<object>({})
    } catch (error: any) {
        return handleError(<ApiGenericErrorResponse>error)
    }
}
