import type { BranchCluster } from '~/composables/types/api/searchDiscover/getBranchClusters'

export type BranchClusterState = {
    branchClusterTemplate: {
        resetData: boolean
        initialClusterSetup: boolean
        branchClusterId: number
        branchClusterName: string
        clusters: BranchCluster[]
    } | null
    moduleBranchClusterTemplate: {
        resetData: boolean
        initialClusterSetup: boolean
        branchClusterId: number
        branchClusterName: string
        clusters: BranchCluster[]
    } | null
    clusterActive: boolean
    clusterToggleActive: boolean
    branchClusterLoading: boolean | null
    moduleQuantitiesMatch: boolean
    clickedBranchClusterId: number
    productGroupFromPreviousProduct: string
}

type BranchClusterActions = {
    updateBranchClusterTemplate: (branchClusterTemplate: BranchClusterState['branchClusterTemplate']) => void
    remove: () => void
    getBranchClusterTemplate: (checkForModules?: boolean) => BranchClusterState['branchClusterTemplate']
    setClusterState: (active: boolean) => void
    setClusterLoadingState: (branchClusterLoading: boolean | null) => void
    getClusterLoadingState: () => boolean | null
    getClusterState: () => boolean
    getClusterCount: (clusterName?: string) => number
    updateModuleClusterTemplate: (
        moduleBranchClusterTemplate: BranchClusterState['moduleBranchClusterTemplate']
    ) => void
    updateBranchClusters: (clusters: BranchCluster[]) => void
    updateModuleClusters: (moduleClusters: BranchCluster[]) => void
    resetModuleClusters: () => void
    getModuleBranchClusterTemplate: () => BranchClusterState['moduleBranchClusterTemplate']
    setModuleQuantitiesMatch: (matchValue: boolean) => void
    getModuleQuantitiesMatch: () => boolean
    setClickedBranchClusterId: (id: number, productGroup?: string) => void
    getClickedBranchClusterId: () => number
    setProductGroupFromPreviousProduct: (productGroup: string) => void
}

export const useBranchClusterStore = defineStore<string, BranchClusterState, any, BranchClusterActions>(
    'branchCluster',
    {
        state: () => ({
            branchClusterTemplate: null,
            moduleBranchClusterTemplate: null,
            clusterActive: true,
            clusterToggleActive: false,
            branchClusterLoading: null,
            moduleQuantitiesMatch: true,
            clickedBranchClusterId: 0,
            productGroupFromPreviousProduct: '',
        }),
        actions: {
            updateBranchClusterTemplate(branchClusterTemplate) {
                if (branchClusterTemplate === null) {
                    this.branchClusterTemplate = null

                    return
                }

                if (typeof branchClusterTemplate?.resetData !== 'undefined') {
                    this.branchClusterTemplate = {
                        ...branchClusterTemplate,
                    }
                }
            },
            updateModuleClusterTemplate(moduleBranchClusterTemplate) {
                this.moduleBranchClusterTemplate = moduleBranchClusterTemplate
            },
            updateModuleClusters(moduleClusters: BranchCluster[]) {
                if (this.moduleBranchClusterTemplate) {
                    this.moduleBranchClusterTemplate.clusters = moduleClusters
                }
            },
            resetModuleClusters() {
                if (this.moduleBranchClusterTemplate) {
                    this.moduleBranchClusterTemplate.clusters?.forEach((cluster, index) => {
                        if (
                            this.moduleBranchClusterTemplate?.clusters?.length &&
                            index < this.moduleBranchClusterTemplate.clusters?.length - 1
                        ) {
                            cluster.branches = []
                            cluster.branchNames = []
                            cluster.partnerIds = {}
                        }
                    })
                }
            },
            updateBranchClusters(clusters: BranchCluster[]) {
                if (this.branchClusterTemplate) {
                    this.branchClusterTemplate.clusters = [...clusters]
                }
            },
            remove() {
                this.branchClusterTemplate = null
            },
            getBranchClusterTemplate(checkForModule: boolean = true) {
                if (checkForModule && this.moduleBranchClusterTemplate) {
                    return this.moduleBranchClusterTemplate
                }

                return this.branchClusterTemplate
            },
            getModuleBranchClusterTemplate() {
                return this.moduleBranchClusterTemplate
            },
            setClusterState(active) {
                this.clusterActive = active
            },
            getClusterState() {
                return this.clusterActive
            },
            setClusterLoadingState(currentState) {
                this.branchClusterLoading = currentState
            },
            getClusterLoadingState() {
                return this.branchClusterLoading
            },
            getClusterCount(clusterName?: string) {
                let count = 1

                if (this.moduleBranchClusterTemplate && clusterName) {
                    const clusterIndex = this.moduleBranchClusterTemplate.clusters.findIndex(
                        (cluster) => cluster.name === clusterName
                    )
                    const branchesCount = this.moduleBranchClusterTemplate.clusters?.[clusterIndex]?.branches?.length
                    if (branchesCount && branchesCount > 0) {
                        count = this.moduleBranchClusterTemplate.clusters?.[clusterIndex]?.branches?.length

                        return count
                    }
                }

                if (this.branchClusterTemplate && clusterName) {
                    const clusterIndex = this.branchClusterTemplate.clusters.findIndex(
                        (cluster) => cluster.name === clusterName
                    )
                    count = this.branchClusterTemplate.clusters?.[clusterIndex]?.branches?.length ?? 1
                }

                return count
            },
            setModuleQuantitiesMatch(matchValue: boolean) {
                this.moduleQuantitiesMatch = matchValue
            },
            getModuleQuantitiesMatch() {
                return this.moduleQuantitiesMatch
            },
            setClickedBranchClusterId(id: number, productGroup?: string) {
                this.clickedBranchClusterId = id
                if (productGroup) {
                    this.productGroupFromPreviousProduct = productGroup
                }

                if (productGroup && this.productGroupFromPreviousProduct !== productGroup) {
                    this.productGroupFromPreviousProduct = productGroup
                    this.clickedBranchClusterId = 0
                }
            },
            getClickedBranchClusterId(): number {
                return this.clickedBranchClusterId
            },
            setProductGroupFromPreviousProduct(productGroup: string) {
                this.productGroupFromPreviousProduct = productGroup
            },
        },
    }
)
