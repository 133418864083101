import getAdditionalOrderFields from '~/composables/api/searchDiscover/getAdditionalOrderFields'
import type { FieldValuesParams } from '~/composables/api/cartConditions/getAdditionalOrderFieldsValues'
import getAdditionalOrderFieldsValues from '~/composables/api/cartConditions/getAdditionalOrderFieldsValues'
import updateAdditionalOrderFields from '~/composables/api/cartConditions/updateAdditionalOrderFields'
import useLocalStorageCache from '~/composables/useLocalStorageCache'
import type {
    AdditionalOrderFieldGroup,
    AdditionalOrderFieldRequestParams,
    AdditionalOrderFieldSelectOption,
    AdditionalOrderFieldValues,
    AdditionalOrderFieldWithValue,
} from '../types/api/searchDiscover'

const ERP_SMART_RETAIL = 'SMART.Retail'
const ERP_SMART_RETAIL_ABVERKAUFSSAISON = '000_005'
const STORAGE_DURATION = 28800000

export default function useAdditionalOrderFields() {
    const { getCachedData, updateCachedData } = useLocalStorageCache<AdditionalOrderFieldGroup[]>(
        LocalStorageNameTypes.ORDER_FIELDS_KEY,
        STORAGE_DURATION
    ) // 8h

    const fieldGroups = shallowRef<AdditionalOrderFieldGroup[]>([])
    const fieldValues = shallowRef<AdditionalOrderFieldValues>({})

    const brandCode = shallowRef('')
    const modelCode = shallowRef('')

    const loadAdditionalOrderFieldsData = async (): Promise<boolean> => {
        const response = await getAdditionalOrderFields()

        // members has erp-system
        if ('groups' in response) {
            fieldGroups.value = response.groups
            updateCachedData(fieldGroups.value)
            return true
        }

        // error, so retry to load next time
        return false
    }

    const loadAdditionalOrderFields = async (): Promise<boolean> => {
        const groups = getCachedData()
        if (!groups) {
            const success = await loadAdditionalOrderFieldsData()
            if (!success) {
                return false
            }
        } else {
            fieldGroups.value = groups
        }

        return true
    }

    const loadFieldValues = async (brand: string, model: string): Promise<boolean> => {
        brandCode.value = brand
        modelCode.value = model

        if (Object.keys(fieldValues.value).length) {
            return true
        }

        const requestParams: FieldValuesParams = {
            brandCode: brand,
            modelCode: model,
        }

        const response = await getAdditionalOrderFieldsValues(requestParams)
        if (response.error) {
            return false
        }
        if ('fields' in response) {
            fieldValues.value = response.fields
        }

        return true
    }

    const updateAdditionalOrderFieldValues = async (fields: any): Promise<boolean> => {
        const params: Omit<AdditionalOrderFieldRequestParams, 'cartId'> = {
            brandCode: '',
            modelCode: '',
            fields: [],
        }

        fieldValues.value = { ...fields }

        for (const [key, value] of Object.entries(fields)) {
            if (typeof value === 'boolean') {
                fields[key] = value ? '1' : '0'
            } else if (typeof value === 'number') {
                fields[key] = `${value}`
            } else {
                fields[key] = value ? value.toString() : ''
            }
        }

        params.fields = fields
        params.brandCode = brandCode.value
        params.modelCode = modelCode.value

        const additionalOrderFields = await updateAdditionalOrderFields(params)

        return !additionalOrderFields.error
    }

    const getFieldValueOfId = (field: any) => fieldValues.value[`${field.fieldId}_${field.fieldsetId}`]

    const getFieldOfAbverkaufssaison = (): AdditionalOrderFieldWithValue | undefined => {
        const smartRetailAbverkaufssaison = fieldGroups.value
            .find((group) => group.name == ERP_SMART_RETAIL)
            ?.fields.find((field) => `${field.fieldId}_${field.fieldsetId}` == ERP_SMART_RETAIL_ABVERKAUFSSAISON)

        return smartRetailAbverkaufssaison
            ? {
                  value: getFieldValueOfId(smartRetailAbverkaufssaison) || undefined,
                  field: smartRetailAbverkaufssaison,
              }
            : undefined
    }

    const parseWithExactness = (floatValue: string, exactness: number) => {
        if (exactness > 0) {
            const parsedValue = parseFloat(floatValue.replace(',', '.'))

            return !isNaN(parsedValue) ? parsedValue.toFixed(exactness).toString() : ''
        }

        return floatValue
    }

    const tryGetAutoSelectedAbverkaufssaison = (
        earliestDeliveryDate: Date,
        selectOptions: AdditionalOrderFieldSelectOption[]
    ): string | undefined => {
        // Define the LT date ranges for each season
        const ltRanges = [
            {
                season: '2404',
                start: new Date('2024-09-16'),
                end: new Date('2024-12-15'),
            }, // Winter 2024
            {
                season: '2501',
                start: new Date('2024-12-16'),
                end: new Date('2025-03-15'),
            }, // Frühjahr 2025
            {
                season: '2502',
                start: new Date('2025-03-16'),
                end: new Date('2025-06-15'),
            }, // Sommer 2025
            {
                season: '2503',
                start: new Date('2025-06-16'),
                end: new Date('2025-09-15'),
            }, // Herbst 2025
            {
                season: '2504',
                start: new Date('2025-09-16'),
                end: new Date('2025-12-15'),
            }, // Winter 2025
            {
                season: '2601',
                start: new Date('2025-12-16'),
                end: new Date('2026-03-15'),
            }, // Frühjahr 2026
            {
                season: '2602',
                start: new Date('2026-03-16'),
                end: new Date('2026-06-15'),
            }, // Sommer 2026
            {
                season: '2603',
                start: new Date('2026-06-16'),
                end: new Date('2026-09-15'),
            }, // Herbst 2026
            {
                season: '2604',
                start: new Date('2026-09-16'),
                end: new Date('2026-12-15'),
            }, // Winter 2026
            {
                season: '2701',
                start: new Date('2026-12-16'),
                end: new Date('2027-03-15'),
            }, // Frühjahr 2027
            {
                season: '2702',
                start: new Date('2027-03-16'),
                end: new Date('2027-06-15'),
            }, // Sommer 2027
            {
                season: '2703',
                start: new Date('2027-06-16'),
                end: new Date('2027-09-15'),
            }, // Herbst 2027
            {
                season: '2704',
                start: new Date('2027-09-16'),
                end: new Date('2027-12-15'),
            }, // Winter 2027
        ]

        // Check the earliest delivery date against the LT ranges
        for (const range of ltRanges) {
            if (earliestDeliveryDate >= range.start && earliestDeliveryDate <= range.end) {
                const matchingOption = selectOptions.find((option) => option.value === range.season)
                if (matchingOption) {
                    return matchingOption.value
                }
            }
        }

        return undefined
    }

    const getFieldValues = (earliestDeliveryDate: Date, force: boolean): AdditionalOrderFieldValues => {
        const result: AdditionalOrderFieldValues = {}

        for (const group of fieldGroups.value) {
            for (const field of group.fields) {
                const fieldKey = `${field.fieldId}_${field.fieldsetId}`
                const fieldValue = getFieldValueOfId(field)
                switch (field.type) {
                    case 'string':
                    case 'text':
                        result[fieldKey] = fieldValue ?? ''
                        break
                    case 'select':
                        if (
                            (fieldValue == undefined || force) &&
                            group.name == ERP_SMART_RETAIL &&
                            fieldKey == ERP_SMART_RETAIL_ABVERKAUFSSAISON
                        ) {
                            result[fieldKey] = tryGetAutoSelectedAbverkaufssaison(
                                earliestDeliveryDate,
                                field.selectOptions
                            )
                            break
                        }
                        result[fieldKey] = fieldValue === undefined ? undefined : `${fieldValue}`
                        break
                    case 'float':
                    case 'int':
                        if (typeof fieldValue === 'number') {
                            result[fieldKey] = fieldValue
                        } else if (typeof fieldValue === 'string') {
                            if (fieldValue === '') {
                                result[fieldKey] = undefined
                            } else if (field.type === 'float') {
                                result[fieldKey] = parseWithExactness(fieldValue, field.exactness)
                            } else {
                                result[fieldKey] = parseInt(fieldValue, 10)
                            }
                        } else {
                            result[fieldKey] = undefined
                        }
                        break
                    case 'boolean':
                        if (typeof fieldValue === 'undefined') {
                            result[fieldKey] = undefined
                        } else if (typeof fieldValue === 'boolean') {
                            result[fieldKey] = fieldValue
                        } else {
                            result[fieldKey] = fieldValue !== '0'
                        }
                        break
                    default:
                        console.warn('unknown type', field.type)
                }
            }
        }
        fieldValues.value = { ...result }

        return fieldValues.value
    }

    const getAdditionalOrderFieldGroups = (): AdditionalOrderFieldGroup[] => fieldGroups.value

    return {
        loadAdditionalOrderFields,
        loadFieldValues,
        getAdditionalOrderFieldGroups,
        getFieldValues,
        updateAdditionalOrderFieldValues,
        getFieldValueOfId,
        loadAdditionalOrderFieldsData,
        tryGetAutoSelectedAbverkaufssaison,
        getFieldOfAbverkaufssaison,
    }
}
