<template>
    <div class="branch-cluster options__container-wrapper spacing-right--s">
        <div class="options__container-headline flex grid--middle spacing-bottom--inner spacing-bottom--xxs">
            {{ moduleName && getModuleQuantitiesMatch() ? $t('Matrix.module_cluster') : $t('Matrix.branch_cluster') }}
            <AtomToggle v-model="currentToggleState" :disabled="cartClosed" @update:model-value="updateBranchCluster" />
        </div>
        <div class="options__item">
            <AtomSelect
                :class="{ select: true, 'disable-select': disableSelect }"
                name="pageSelect"
                :model-value="activeOption"
                :disabled="!currentToggleState || cartClosed"
                :options="options"
                :with-search="true"
                @update:model-value="applyBranchCluster"
            />
        </div>
    </div>
    <LazyAtomModal
        :class="{
            'branch-cluster-modal': true,
            'close-button-only': moduleItems.length,
        }"
        :open="!getClusterState()"
        :buttons="modalButtons"
        :title="$t('Matrix.branch_classes_not_compatible')"
        :text="
            moduleItems.length
                ? $t('Matrix.branch_classes_not_compatible_text_modules')
                : $t('Matrix.branch_classes_not_compatible_text')
        "
        :show-x-button="false"
        @update:open="resetToLastIndex"
        @button-clicked="buttonClicked"
    />
</template>

<script lang="ts" setup>
import type { ModalButton, SelectOption } from '~/composables/types/ui'
import type { MatrixModuleItem, MatrixOrderItem } from '~/composables/types/api/cartConditions/matrix'

const props = defineProps<{
    productAbstractId: string | null
    branchClusterSelected: string | null
    cartClosed: boolean
    orderItems: MatrixOrderItem[]
    moduleItems: MatrixModuleItem[]
    moduleName: string | null
    allocationId: string | null
}>()

const options: Ref<SelectOption[]> = ref([])
const oldActiveOption: Ref<number> = ref(0)
const initialClusterSetup: Ref<boolean> = ref(false)
const activeOption: Ref<number> = ref(0)
const disableSelect: Ref<boolean> = ref(false)
const { $t } = useNuxtApp()

const modalButtons: ModalButton[] = !props.moduleItems.length
    ? [
          {
              text: $t('General.cancel'),
              type: 'secondary',
          },
          {
              text: $t('Matrix.reset_cluster'),
              type: 'primary',
          },
      ]
    : [
          {
              text: $t('General.close'),
              type: 'primary',
          },
      ]

const { branchClusterData, loadBranchClusters, updateBranchClusterById, getClusterState, setClusterState } =
    useBranchClusters(props.productAbstractId)

let { clusterToggleActive } = useBranchClusterStore()

const { getModuleQuantitiesMatch, setClickedBranchClusterId, getClickedBranchClusterId } = useBranchClusterStore()

const currentToggleState = ref(false)
const moduleItemsExist = computed(
    () => (props.moduleName !== null || props.allocationId !== null) && props.moduleItems.length > 0
)

const applyBranchCluster = (index: string | number | null | undefined, resetData: boolean = false) => {
    oldActiveOption.value = activeOption.value

    const indexNumber = Number(index)
    if (isNaN(indexNumber)) {
        updateBranchClusterById(null, resetData, initialClusterSetup.value, moduleItemsExist.value)
        activeOption.value = 0
        return
    }

    updateBranchClusterById(indexNumber, resetData, initialClusterSetup.value, moduleItemsExist.value)
    activeOption.value = indexNumber
    initialClusterSetup.value = false
    setClickedBranchClusterId(indexNumber)
}

const resetToLastIndex = () => {
    activeOption.value = oldActiveOption.value
    if (props.moduleName) {
        activeOption.value = 0
        currentToggleState.value = false
    }

    if (!props.cartClosed) {
        applyBranchCluster(activeOption.value)
    }
    setClusterState(true)
    disableSelect.value = false
}

const buttonClicked = (index: number) => {
    if (index === 0) {
        resetToLastIndex()

        return
    }

    applyBranchCluster(activeOption.value, true)
    setClusterState(true)
}

const setSelectableOptions = () => {
    if (clusterToggleActive && branchClusterData.value) {
        options.value =
            branchClusterData.value.branchClusters?.map((cluster) => ({
                name: cluster.branchClusterName,
                value: cluster.branchClusterId,
            })) ?? []

        if (props.moduleName && getModuleQuantitiesMatch() && branchClusterData?.value?.branchClusters?.length === 1) {
            disableSelect.value = true
        }
    }
}

const resetBranchCluster = () => {
    currentToggleState.value = false
    disableSelect.value = false
    options.value = []
    activeOption.value = 0
    applyBranchCluster(null)
}

const updateBranchCluster = async (active?: boolean, initialLoad: boolean = false) => {
    let cluster = false

    if (typeof active !== 'undefined') {
        clusterToggleActive = active
    }

    if (props.orderItems.length && !props.moduleName && props.branchClusterSelected !== '-1' && !initialLoad) {
        resetBranchCluster()

        return
    }

    if (clusterToggleActive) {
        const moduleName = getModuleQuantitiesMatch() ? props.moduleName : null
        const allocationId = getModuleQuantitiesMatch() ? props.allocationId : null
        cluster = await loadBranchClusters(initialLoad, moduleName, allocationId)
        currentToggleState.value = true
    }

    if (!cluster || (!branchClusterData.value?.branchClusters?.length && !branchClusterData.value?.modules?.length)) {
        resetBranchCluster()

        return
    }

    setSelectableOptions()

    initialClusterSetup.value = true
    const clusterId =
        branchClusterData.value?.branchClusters?.[0]?.branchClusterId ??
        branchClusterData.value?.modules?.[0]?.branchClusterId
    let selectedCluster = Number(!props.branchClusterSelected ? clusterId : props.branchClusterSelected)

    if (clusterId && branchClusterData?.value?.branchClusters?.length === 1) {
        if ((props.moduleName && getModuleQuantitiesMatch()) || !props.moduleName) {
            selectedCluster = clusterId
            activeOption.value = clusterId
        }
    }

    if (getClickedBranchClusterId() !== 0 && props.branchClusterSelected == null) {
        selectedCluster = getClickedBranchClusterId()
    }

    applyBranchCluster(selectedCluster, false)
}

onMounted(async () => {
    oldActiveOption.value = 0
    activeOption.value = 0

    if (props.branchClusterSelected !== '-1') {
        currentToggleState.value = Boolean(props.branchClusterSelected || !props.orderItems.length)
        clusterToggleActive = true
    } else {
        clusterToggleActive = false
    }

    await updateBranchCluster(undefined, true)
})
</script>

<style lang="scss" scoped>
.branch-cluster {
    .selected.value-selected,
    .select {
        height: rem(50);
    }

    :deep(.modal-box__close) {
        display: flex;
    }
}

.close-button-only {
    :deep(.modal-box__buttons) {
        justify-content: flex-start;
    }
}

.disable-select {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;

    @include helper-color(light-gray);
    @include helper-color-bg(state-disabled-text);
}
</style>
