import updateMatrix from '~/composables/api/cartConditions/updateMatrix'
import type { PromiseResponseData } from '~/composables/types/api/apiResponse'
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper'
import type { MatrixData } from '~/composables/types/api/searchDiscover/getMatrix'
import type { MatrixNosItem, NosKey } from '~/composables/service/matrix/useMatrixOrderItems'

export type NosSelectionItem = MatrixNosItem & {
    standardStock?: number
    storedQuantity?: number
    storedStandardStock?: number
}

const updateDelay = 1000

export default function useMatrixNosItems(errorHandler: (message: any) => void, cartUuid: string | null) {
    const { $emitter } = useNuxtApp()
    let matrixData: MatrixData | null
    const cartsStore = useCartsStore()
    let nosItems: { [index: string]: NosSelectionItem } = {}
    let timeout: number | undefined
    let updatePromise: PromiseResponseData | undefined
    let storedCartUuid = cartUuid ?? cartsStore.activeCart!
    const now = useMatrixDateHelper().dateToString()

    const createNosKey = (values: NosKey): string => {
        const nosDate = values.validFrom ?? now

        return `${values.gtin}-${values.branchId}-${nosDate}-nos`
    }

    const backendUpdatedHandler = (response: any) => {
        updatePromise = undefined
        if (response.error) {
            errorHandler(response)
        } else {
            $emitter.$emit('update:updatingQuantities', true)
        }
    }

    const stopTimeout = () => {
        if (timeout !== undefined) {
            clearTimeout(timeout)
            timeout = undefined

            window.removeEventListener('beforeunload', onBeforeUnload)
        }
    }

    const updateMatrixNos = (): PromiseResponseData | null => {
        stopTimeout()

        const writeableItems: MatrixNosItem[] = Object.values(nosItems).filter((item) => item.writable)

        if (matrixData === null || !writeableItems.length) {
            return null
        }

        updatePromise = updateMatrix(matrixData, writeableItems, storedCartUuid, true)

        updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
            errorHandler(err)
            $emitter.$emit('update:updatingQuantities', false)
        })

        return updatePromise
    }

    const updateNosData = (response: MatrixData) => {
        if (timeout !== undefined) {
            // Force immediate update if update from old matrix still pending
            stopTimeout()
            updateMatrixNos()
        }
        nosItems = {}
        matrixData = response

        const items = response.items?.nosItems
            .map((item) => {
                if (!response.concreteProductsByGtin[item.gtin]) {
                    return false
                }

                return {
                    gtin: item.gtin,
                    validFrom: item.validFrom,
                    validTo: item.validTo ?? null,
                    branchId: item.branchId,
                    partnerId: response?.branches.find((branch) => branch.label === item.branchId)?.partnerId,
                    minStock: item.minStock,
                    standardStock: item.standardStock,
                    writable: item.writable,
                }
            })
            .filter(Boolean) as any

        for (const item of items) {
            nosItems[createNosKey(item)] = item
        }
    }

    const onBeforeUnload = () => {
        updateMatrixNos()
        stopTimeout()
    }

    const updateMatrixCartDelayed = (): Promise<any> | null => {
        stopTimeout()
        window.addEventListener('beforeunload', onBeforeUnload)

        return new Promise((resolve) => {
            storedCartUuid = cartUuid ?? cartsStore.activeCart!

            timeout = window.setTimeout(() => {
                updateMatrixNos()?.then(resolve)
            }, updateDelay)
        })
    }

    const updateMatrixNosQuantity = (
        params: Omit<MatrixNosItem, 'writable' | 'releasedAt'>,
        validFromDateOld?: string
    ) => {
        const newKey = createNosKey(params)
        const oldKey = createNosKey({ ...params, validFrom: validFromDateOld })
        const oldItemToReset = nosItems[oldKey]?.writable && validFromDateOld === nosItems[oldKey]?.validFrom

        if (oldItemToReset) {
            nosItems[oldKey] = {
                ...nosItems[oldKey],
                standardStock: 0,
                minStock: 0,
            }
        }

        nosItems[newKey] = {
            ...params,
            writable: nosItems[oldKey]?.writable ?? true,
            releasedAt: nosItems[oldKey]?.releasedAt ?? null,
        }

        return updateMatrixCartDelayed()
    }

    const resetMatrixNosQuantity = (): Promise<any> | null => {
        if (matrixData === null) {
            return null
        }

        Object.values(nosItems).map((nosItem) => {
            if (nosItem.writable) {
                updatePromise = updateMatrixNosQuantity({
                    ...nosItem,
                    minStock: 0,
                    standardStock: 0,
                }) as Promise<any>
            }
        })

        return updatePromise as Promise<any> | null
    }

    const getNosStandardStock = (deliveryDate: string, branchId: string, partnerId: string, gtin: string): number => {
        const key = createNosKey({
            gtin: gtin,
            branchId: branchId,
            validFrom: deliveryDate,
        })

        return nosItems[key]?.standardStock ?? 0
    }

    const getNosQuantity = (deliveryDate: string, branchId: string, partnerId: string, gtin: string): number => {
        const key = createNosKey({
            gtin: gtin,
            branchId: branchId,
            validFrom: deliveryDate,
        })

        return nosItems[key]?.minStock ?? 0
    }

    const getNosQuantitiesForDate = (date: string): NosSelectionItem[] => {
        const result = <NosSelectionItem[]>[]

        for (const item of Object.values(nosItems)) {
            if (item.validFrom === date) {
                result.push(item)
            }
        }

        return result
    }

    return {
        updateNosData,
        getNosQuantity,
        getNosStandardStock,
        getNosQuantitiesForDate,
        updateMatrixNosQuantity,
        resetMatrixNosQuantity,
    }
}
