import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper'
import type {
    MatrixAvailableDeliveryDate,
    MatrixConcreteProduct,
    MatrixData,
    ResponseBranch,
} from '~/composables/types/api/searchDiscover/getMatrix'
import type {
    CellLockEvaluationData,
    MatrixCell,
    MatrixColumn,
    MatrixContentData,
    MatrixContentHeaderInfo,
    MatrixHeader,
    MatrixHeaderSection,
} from '~/composables/types/matrix/useMatrixContentDataTypes'
import useMatrixBlockedCellEvaluationHelper from '~/composables/matrix/useMatrixBlockedCellEvaluationHelper'
import { useBranchClusterStore } from '~/composables/stores/useBranchClusterStore'
import type { BranchCluster } from '~/composables/types/api/searchDiscover/getBranchClusters'
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore'

const farFuture = '2200-01-01'
const distantPast = '2000-01-01'

const getConcreteProductsByColor = (colorKey: string, matrixData: MatrixData): MatrixConcreteProduct[] =>
    Object.values(matrixData.concreteProductsBySizeAndColor)
        .flatMap((sizes) => Object.entries(sizes))
        .filter(([_, product]) => product.colorKey)
        .map(([_, product]) => product)

export default function useMatrixContentData(
    matrixData: MatrixData,
    getAllAvailableDeliveryDatesByGtin: (gtin: string, valid: boolean) => MatrixAvailableDeliveryDate[]
) {
    const matrixPricesStore = useMatrixPricesStore()
    const dateFormat = useMatrixDateHelper().formatDateString
    const { getBranchClusterTemplate } = useBranchClusterStore()
    const { blockedCellEvaluations } = useMatrixBlockedCellEvaluationHelper(
        matrixData,
        getAllAvailableDeliveryDatesByGtin
    )

    const createHeaderInfo = (color: string): MatrixContentHeaderInfo | undefined => {
        const concretes = getConcreteProductsByColor(color, matrixData)

        if (concretes.length === 0) {
            return undefined
        }

        const colorLabelSet = new Set<string>()
        let found = false
        let fromDate = farFuture
        let toDate = distantPast
        let deadline = farFuture
        let firstConcreteProductByColor = concretes[0]
        const lowestPricesByType: { [key: string]: number[] } = {
            purchasePrice: [],
            purchasePriceOriginal: [],
            sellPrice: [],
            sellPriceOriginal: [],
        }

        concretes
            .filter((concrete) => concrete.colorKey === color)
            .forEach((concrete) => {
                const possibleDates = matrixData.availableDeliveryDatesByGtin?.[concrete.gtin]
                firstConcreteProductByColor = concrete

                concrete.labels.forEach((label) => {
                    colorLabelSet.add(label)
                })

                if (possibleDates) {
                    possibleDates.forEach((possibleDate) => {
                        found = true
                        fromDate = possibleDate.fromDate < fromDate ? possibleDate.fromDate : fromDate
                        toDate = possibleDate.toDate > toDate ? possibleDate.toDate : toDate
                        deadline = possibleDate.deadlineDate < deadline ? possibleDate.deadlineDate : deadline
                    })
                }

                if (concrete.purchasePrice) {
                    lowestPricesByType.purchasePrice.push(concrete.purchasePrice)
                }
                if (concrete.purchasePriceOriginal) {
                    lowestPricesByType.purchasePriceOriginal.push(concrete.purchasePriceOriginal)
                }
                if (concrete.sellPrice) {
                    lowestPricesByType.sellPrice.push(concrete.sellPrice)
                }
                if (concrete.sellPriceOriginal) {
                    lowestPricesByType.sellPriceOriginal.push(concrete.sellPriceOriginal)
                }
            })

        const priceHasDifferences = (prices: number[]) => prices.some((price) => price !== prices[0])

        return {
            image: matrixData.imagesByColor[color],
            name: firstConcreteProductByColor.name,
            labels: Array.from(colorLabelSet),
            purchasePrice:
                lowestPricesByType.purchasePrice.length > 0 ? Math.min(...lowestPricesByType.purchasePrice) : undefined,
            purchasePriceIntersportType: firstConcreteProductByColor.purchasePriceIntersportType,
            sellPrice: lowestPricesByType.sellPrice.length > 0 ? Math.min(...lowestPricesByType.sellPrice) : undefined,
            sellPriceIntersportType: firstConcreteProductByColor.sellPriceIntersportType,
            purchasePriceOriginal:
                lowestPricesByType.purchasePriceOriginal.length > 0
                    ? Math.min(...lowestPricesByType.purchasePriceOriginal)
                    : undefined,
            purchasePriceOriginalIntersportType: firstConcreteProductByColor.purchasePriceOriginalIntersportType,
            sellPriceOriginal:
                lowestPricesByType.sellPriceOriginal.length > 0
                    ? Math.min(...lowestPricesByType.sellPriceOriginal)
                    : undefined,
            sellPriceOriginalIntersportType: firstConcreteProductByColor.sellPriceOriginalIntersportType,
            deadline: found ? dateFormat(deadline) : null,
            fromDate: found ? dateFormat(fromDate) : null,
            toDate: found ? dateFormat(toDate) : null,
            packagingUnit: firstConcreteProductByColor.packagingUnit,
            minQuantityAbstract: matrixData.minQuantityAbstract,
            minQuantityConcrete: matrixData.minQuantityConcrete,
            minQuantityColor: matrixData.minQuantityColor,
            purchasePriceFromLabel: priceHasDifferences(lowestPricesByType.purchasePrice) ? true : undefined,
            sellPriceFromLabel: priceHasDifferences(lowestPricesByType.sellPrice) ? true : undefined,
            purchasePriceOriginalFromLabel: priceHasDifferences(lowestPricesByType.purchasePriceOriginal)
                ? true
                : undefined,
            sellPriceOriginalFromLabel: priceHasDifferences(lowestPricesByType.sellPriceOriginal) ? true : undefined,
        }
    }

    const getBranchesForBranchCluster = (): BranchCluster[] | undefined => getBranchClusterTemplate()?.clusters

    const createCell = (cellData: CellLockEvaluationData): MatrixCell =>
        blockedCellEvaluations(cellData, getBranchesForBranchCluster())

    const getMatrixContentData = (date: string, groupBy: string, nosTabSelected: boolean): MatrixContentData => {
        const createHeaderInfoForRowHeader = (header: MatrixHeader) => {
            const info = createHeaderInfo(`${header.key}`)
            if (info) {
                header.info = info
            }
        }

        const createSectionsForBranchGrouping = (): MatrixHeaderSection[] => {
            const sections: MatrixHeaderSection[] = []

            matrixData.branches.forEach((branch) => {
                const columns: MatrixColumn[] = []

                matrixData.sizes.forEach((columnHeader) => {
                    const size = columnHeader.key
                    const cells: MatrixCell[] = []

                    matrixData.colors.forEach((rowHeader) => {
                        const color = rowHeader.key
                        cells.push(
                            createCell({
                                branch: branch.label,
                                originalBranch:
                                    matrixData.originalBranches.find(
                                        (originalBranch) => originalBranch.partnerId === branch.partnerId
                                    )?.label ?? branch.label,
                                partnerId: branch.partnerId,
                                color: color.toString(),
                                size: size.toString(),
                                date,
                                nosTabSelected,
                            })
                        )
                    })

                    columns.push({ cells })
                })

                if (branch.label) {
                    sections.push({
                        key: branch.label,
                        label: `${branch.label} ${!getBranchesForBranchCluster() && branch.name ? branch.name : ''}`,
                        columns,
                    })
                }
            })

            return sections
        }

        const createSectionsForColorGrouping = (): MatrixHeaderSection[] => {
            const sections: MatrixHeaderSection[] = []

            matrixData.colors.forEach((color) => {
                const columns: MatrixColumn[] = []

                matrixData.sizes.forEach((columnHeader) => {
                    const size = columnHeader.key
                    const cells: MatrixCell[] = []

                    matrixData.branches.forEach((branch) => {
                        cells.push(
                            createCell({
                                branch: branch.label,
                                originalBranch:
                                    matrixData.originalBranches.find(
                                        (originalBranch) => originalBranch.partnerId === branch.partnerId
                                    )?.label ?? branch.label,
                                partnerId: branch.partnerId,
                                color: color.key.toString(),
                                size: size.toString(),
                                date,
                                nosTabSelected,
                            })
                        )
                    })

                    columns.push({ cells })
                })

                const sectionInfo = createHeaderInfo(`${color.key}`)
                sections.push({
                    key: color.key,
                    label: color.label,
                    columns,
                    info: sectionInfo,
                })
            })

            return sections
        }

        let columnHeaders: MatrixHeader[]
        let rowHeaders: MatrixHeader[]
        let sections: MatrixHeaderSection[]

        if (groupBy === 'branch') {
            columnHeaders = matrixData.sizes
            rowHeaders = matrixData.colors
            rowHeaders.forEach(createHeaderInfoForRowHeader)
            sections = createSectionsForBranchGrouping()
        } else {
            columnHeaders = matrixData.sizes
            rowHeaders = matrixData.branches.map((branch: ResponseBranch) => ({
                key: branch.label,
                label: `${branch.label} ${!getBranchesForBranchCluster() && branch?.name ? branch.name : ''}`,
            }))
            sections = createSectionsForColorGrouping()
        }

        matrixPricesStore.setSectionsForInfoData(sections)

        return {
            columnHeaders,
            rowHeaders,
            sections,
            branchSections: createSectionsForBranchGrouping(),
            minimumAmount: 0,
            minPackagingUnit: matrixData.minPackagingUnit,
            maxPackagingUnit: matrixData.maxPackagingUnit,
        }
    }

    return {
        getMatrixContentData,
    }
}
