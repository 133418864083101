import type { UserFlowStateUpdateParams } from '~/composables/types/api/cartConditions/userflow'
import updateUserFlowState from '~/composables/api/cartConditions/updateUserFlowState'

export default function () {
    const setUserFlowState = async (params: UserFlowStateUpdateParams, state: 'EDITING' | 'DONE'): Promise<boolean> => {
        const setUserState = await updateUserFlowState(params, state)

        return !setUserState.error
    }

    return {
        setUserFlowState,
    }
}
