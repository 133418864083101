<template>
    <mini-carts-micro-frontend
        v-if="config.public.ccApiCartServiceUrl"
        v-bind="componentProps"
        data-testid="e2e-cart-flyout-button"
        :class="{ 'matrix-flyout': showInMatrix }"
    />
</template>

<script lang="ts" setup>
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes'

const props = withDefaults(
    defineProps<{
        showInMatrix?: boolean
    }>(),
    {
        showInMatrix: false,
    }
)

const { $t, $emitter } = useNuxtApp()
const cartsStore = useCartsStore()
const toasts = useToasts()

const { getAccessToken } = ccMicroFrontend()
const accessToken = ref(getAccessToken())
const config = useRuntimeConfig()

const componentProps = ref({
    'api-base-url': config.public.ccApiCartServiceUrl,
    'auth-token': accessToken,
    'carts-path': NuxtLinkNameTypes.CARTS_PAGE,
    'cart-path': NuxtLinkNameTypes.CART_PAGE,
    'matrix-flyout': props.showInMatrix ? 'true' : 'false',
})

const updateMatrixFromFlyout = (event: Event) => {
    if (event instanceof CustomEvent) {
        if (props.showInMatrix) {
            cartsStore.updateCachedCart(event.detail.id, event.detail.name, event.detail.state)
        }

        $emitter.$emit('updateCartId', {
            id: event.detail.id,
        })

        if (props.showInMatrix) {
            toasts.add({
                type: 'success',
                headline: $t('Cart.cart_selected'),
                text: $t('Cart.cart_selected_text', [event.detail.name]),
            })
        }
    }
}

onMounted(() => {
    window.addEventListener('updateMatrixFromFlyout', updateMatrixFromFlyout)
})

onBeforeUnmount(() => {
    window.removeEventListener('updateMatrixFromFlyout', updateMatrixFromFlyout)
})
</script>
<style lang="scss" scoped>
.matrix-flyout {
    width: 100%;
}
</style>
